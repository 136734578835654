
import { useRoute, useRouter } from "vue-router";
import { ComputedRef, defineComponent, ref, Ref, watch } from "vue";
import {
  packageLocationsArray,
  packageLocationTypes,
  TPackageLocation
} from "@/hooks/esim/location-packages/types/locationPackage.types";
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import ShadowControl from "@/components/Form/ShadowControl.vue";
import SelectDropdown from "@/components/ui/Dropdown/SelectDropdown.vue";
import {
  IUseLocationPackages,
  useLocationPackages
} from "@/hooks/esim/location-packages/useLocationPackages";
import AppExpendedTable from "@/components/Table/ts/AppExpendedTable.vue";
import { ITableHeadCell } from "@/components/Table/ts/interfaces/TableStructure";
import AppTableController from "@/components/Table/ts/AppTableController.vue";
import CountryPackageCard from "@/components/Packages/eSIM/CountryPackageCard.vue";
import {
  ILocationPackageListItemEditDto,
  ILocationPackageListItemPlan,
  IOptionsPlan
} from "@/hooks/esim/location-packages/types/useLocationPackageListType";
import EditLocationPackageDialog from "@/components/Packages/eSIM/EditLocationPackageDialog.vue";
import DeleteConfirmDialog from "@/components/ui/Modal/Dialog/instances/DeleteConfirmDialog.vue";
import { computed } from "@vue/reactivity";
import {
  locationKeys,
  TLocationName
} from "@/hooks/esim/location-packages/modules/locationKeys";
import {
  IPlanOperator,
  IRemoveLocationPackageDto
} from "@/api/services/packages/esim/location-packages/types";
import { IEsimDirectoryDefault } from "@/api/interfaces/esim-directory/common";
import { useStore } from "vuex";

function getCurrentLocationType(path: string) {
  const locationType: string | null = path.split("/")[3] || null;

  return (
    packageLocationsArray.find(type => type === locationType) ||
    packageLocationsArray[0]
  );
}

interface ILocationPackagesSetup extends IUseLocationPackages {
  currentLocationType: ComputedRef<TPackageLocation>;
  locationName: ComputedRef<TLocationName>;
}

const initialDeleteData: IRemoveLocationPackageDto = {
  packageId: -1,
  locationType: locationKeys[packageLocationTypes.regions]
    .single as TLocationName
};

export const initialEditData: ILocationPackageListItemEditDto = {
  title: "",
  planName: "",
  bundleId: null,
  dataType: null,
  validity: null,
  purchaseType: null,
  defaultPrice: 0,
  discountAmount: 0,
  discount: false,
  priceWithDiscount: null,
  traffic: {} as IEsimDirectoryDefault,
  currentPlans: [],
  currentZoneName: "",
  locationId: null,
  packageId: null,
  zone_i_product: null,
  currentLocationName: "",
  packageCommission: null,
  currentZoneId: 0
};

export default defineComponent({
  name: "LocationPackages",
  components: {
    DeleteConfirmDialog,
    EditLocationPackageDialog,
    CountryPackageCard,
    AppTableController,
    AppExpendedTable,
    SelectDropdown,
    ShadowControl,
    PageMainHeading
  },

  setup(): ILocationPackagesSetup {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const currentLocationType: Ref<TPackageLocation> = ref(
      getCurrentLocationType(route.path)
    );

    router.afterEach(({ path }) => {
      currentLocationType.value = getCurrentLocationType(path);
    });

    return {
      ...useLocationPackages({
        locationType: currentLocationType
      }),
      currentLocationType: computed(() => currentLocationType.value),
      locationName: computed(
        (): TLocationName => {
          const location: TPackageLocation =
            packageLocationsArray.find(
              (location: string) => location === currentLocationType.value
            ) || packageLocationsArray[0];

          return locationKeys[location].single as TLocationName;
        }
      ),
    };
  },

  data(): {
    editData: ILocationPackageListItemEditDto;
    createPackageDialogOpened: boolean;
    deleteConfirmDialogOpened: boolean;
    isEditing: boolean;
    editLoading: boolean;
    deleteLoading: boolean;
    deleteData: IRemoveLocationPackageDto;
    selectedPlanOperators: IPlanOperator[];
    selectedLocationName: string;
    selectedLocationType: TPackageLocation | "";
    localLocationId: number;
  } {
    return {
      editData: { ...initialEditData },
      editLoading: false,
      deleteLoading: false,
      createPackageDialogOpened: false,
      deleteConfirmDialogOpened: false,
      isEditing: false,
      deleteData: { ...initialDeleteData },
      selectedPlanOperators: [],
      selectedLocationName: "",
      selectedLocationType: "",
      localLocationId: -1
    };
  },

  computed: {
    tableHead(): ITableHeadCell[] {
      const { byLocationName, byUpdatedAt } = this.currentSort;
      return [
        {
          size: "xl",
          label: this.currentSortKeyLocationName,
          id: "location",
          sort: {
            keyName: byLocationName.keyName,
            order: byLocationName.order
          }
        },
        {
          size: "md",
          label: "Packages",
          id: "location-packages"
        },
        {
          size: "lg",
          label: "Date created",
          id: "location-created-date"
        },
        {
          label: "Updated by",
          id: "location-updated-by",
          size: "lg",
          sort: {
            order: byUpdatedAt.order,
            keyName: byUpdatedAt.keyName
          }
        }
      ];
    }
  },

  watch: {
    locationName: {
      immediate: true,
      handler(name: TLocationName) {
        this.deleteData.locationType = name;
        this.currentLocationType = getCurrentLocationType(name);
      }
    },
  },

  created() {
    // request data for edit and create in background
    this.$store.dispatch("fetchBundles");
    this.$store.dispatch("fetchPurchaseTypes");
    this.$store.dispatch("fetchValidityTypes");
    this.$store.dispatch("fetchDataTypes");
  },

  methods: {
    // set plans list accepted to current location
    startPackageCreating(
      plan: ILocationPackageListItemPlan,
      plans: ILocationPackageListItemPlan[]
    ) {
      const { $packages = [] } = plan.packages?.[0]?.[1]?.[0] || {};
      this.selectedPlanOperators = plan.planOperators;
      const {
        currentPlans = plans,
        currentZoneName = "",
        purchaseType = null,
        zone_i_product
      } = $packages[0]?.editData ?? {};
      this.editData = {
        ...initialEditData,
        discount: plan.discount,
        discountAmount: plan.discountAmount,
        currentZoneName,
        purchaseType,
        defaultPrice: 0,
        locationId: plan.locationId,
        selectedPlan: plan,
        zone_i_product,
        currentLocationName: this.locationName,
        currentPlans: currentPlans.map((currentPlan: IOptionsPlan) => ({
          ...currentPlan,
          selected: plan.id === currentPlan.id
        }))
      };

      this.isEditing = false;
      this.createPackageDialogOpened = true;
    },

    startPackageEditing(
      planOperators: IPlanOperator[],
      editData: ILocationPackageListItemEditDto
    ) {
      this.editData = editData;
      editData.currentLocationName = this.locationName;
      this.isEditing = true;
      this.selectedPlanOperators = planOperators;
      this.createPackageDialogOpened = true;
    },

    endPackageEditing() {
      this.editData = { ...initialEditData };
      this.createPackageDialogOpened = false;
    },

    async updateSubmitHandler(data: ILocationPackageListItemEditDto) {
      this.editLoading = true;
      const {
        success,
        message = "Something wrong"
      } = await this.$store.dispatch("updateLocationPackages", data);

      if (!success) {
        await this.$store.dispatch("showErrorNotification", message);
        this.editLoading = false;

        return;
      }

      this.createPackageDialogOpened = false;
      this.editLoading = false;
      this.editData = { ...initialEditData };
      await this.fetchPackages(false);
    },

    // Delete

    startDeleting(packageId: number) {
      this.deleteData.packageId = packageId;
      this.deleteConfirmDialogOpened = true;
    },

    async deleteConfirm() {
      this.deleteLoading = true;
      const { success, message = "" } = await this.$store.dispatch(
        "removeLocationPackage",
        this.deleteData
      );

      this.deleteLoading = false;
      this.deleteConfirmDialogOpened = false;

      if (!success) {
        if (message) {
          await this.$store.dispatch("showErrorNotification", message);
        }
        return;
      }

      await this.fetchPackages();
    },

    deleteCancel() {
      this.deleteData.packageId = -1;
      this.deleteConfirmDialogOpened = false;
    },

    onAccordionOpen(locationId: number) {
      this.localLocationId = locationId;
      this.selectedLocationName =
        this.locationPackagesList.find(({ id }) => id == locationId)?.name ||
        "";
    }
  }
});
